.mainDiv {
  width: 100%;
  overflow-x: hidden;
}
.wrapperStyle {
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 50px;
}
.textPadiing {
  padding-top: 20px;
  font-family: Syne;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  margin-right: 80px;
  color: #fff;
  text-justify: auto;
}
.textLink {
  text-decoration: none;
  padding-top: 20px;
  font-family: Syne;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  margin-right: 80px;
  color: #fff;
  font-weight: 600;
}
.titleText {
  padding-top: 20px;
  font-family: Syne;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  margin-right: 80px;
  color: #fff;
  font-weight: bold;
}
.listText {
  padding-top: 10px;
  font-family: Syne;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  margin-right: 80px;
  color: #fff;
  font-weight: bold;
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  background-color: transparent;
}
.footer ul {
  list-style: none;
}
.footer li a {
  text-decoration: none;
  width: 100%;
  color: #fff;
  padding-right: 50px;
}

/* media queries */

@media screen and (max-width: 480px) {
  body {
    overflow-x: hidden !important;
  }
  .textPadiing{
    margin-right: 0px!important;
    text-align: justify;
    font-size: 14px;
  }
  .wrapperStyle{
    padding-left: 0px!important;
    padding-right:0px!important;
    margin-left: 20px;
    margin-right: 10px;
  }
  .footer{
    text-align: left;
    padding-left: 15px;
  }
  .footer li a{
    height: 50px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footer{
    text-align: left;
    padding-left: 15px;
  }
  
}
@media (min-width: 768px) and (max-width: 1024px){
  .textPadiing{
    margin-right: 0px!important;
    text-align: justify;
    font-size: 14px;
  }
  .wrapperStyle{
    padding-left: 0px!important;
    padding-right:0px!important;
    margin-left: 20px;
    margin-right: 10px;
  }
}
 @media (min-width: 1025px) and (max-width: 1280px){
  
 }
 @media (min-width: 1281px) {
  
 }