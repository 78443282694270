
.mainDiv {
  width:100%;
  overflow-x: hidden;
  min-height: 100%;
}
.textStyle{
  font-family: "Syne";
  font-size: 30px;
  font-weight: bold;
  color: #fff;
}
.textStyle1{
  font-family: "Syne";
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-left: 20px;
}
.input-container input {
  margin-bottom: 30px;

}
.textarea{
  margin-bottom: 30px;

}

.input-container input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}

.rowWrapper{
    flex-direction: row;
    display: flex;
}

  .textAreaWrapper{
    margin-left: 30px;
  }

  .contactContainer{
   margin-left: 100px;
  }
  .buttonStyle{
    background-color: #F9E300;
    border-radius: 33px;
    height: 65px;
    width: 65px;
    border: 0;
    align-items: center;
    justify-content: center;
  }

  .titleWrapper{
    flex-direction: column;
    display: flex;
    margin-top: -20px;
    margin-left: 10px;
    /* background-color: red; */
  }
  .subTitle{
    color: #fff;
    font-size: 16px;
    font-family: "Syne";
    margin-top: -10px;
  }

.inputContainer{
  padding-top: 20px;
}
.submitButton{
  width: 120px;
  height: 40px;
  border-radius: 10px;
  background-color: #F9E300;
  border: none;
}
.submitButtonContainer{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 5em;
}
.buttonText{
  font-family: 'Syne';
font-weight: Bold;
font-size: 18px;
line-height: 26px;
color: #000;
}
.rowWrapper1{
  flex-direction: row;
  display: flex;
padding-bottom: 50px;

}
.phoneIconStyle{
  width: 25px;
  height: 25px;
  color: #000;
}
/* media queries */

@media screen and (max-width: 480px) {

    body {
      overflow-x: hidden!important
    }
  .rowWrapper{
    flex-direction: column;
    display: flex;
}
.bgImage {
    width:100%;
    overflow-x: hidden;
  }

.textAreaWrapper{
  margin-left: 0px;
}
.contactContainer{
  margin:auto;
}
.footer li a{
  padding-right:0px;
  display: block;
  padding-top: 20px;
  margin-left: 1px;
  /* padding: auto; */
  height: 50px;
}
.footer ul{
  margin:0px;
  padding:0px;
}
.rowWrapper1{
  /* flex-direction: column; */
  margin-left: 15px;
}
.buttonStyle{
  margin: auto;
  padding: auto;
  height: 50px;
  width: 50px;
}
.submitButtonContainer{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 10px;
}
.subTitle{
  /* margin-top: 0px; */
  margin-right: 10px;
  font-size: 14px;
  margin-top: -20px;
}
.textStyle{
  font-size: 20px;
}
.titleWrapper{
  margin-top: -28px;
}
.footer{
  text-align: left;
  padding-left: 15px;
}
.footer li a{
  height: 50px;
}

}


@media (min-width: 481px) and (max-width: 767px) {

  .bgImage {
    width:100%;
    overflow-x: hidden;
    height: 100%;
  }
  .rowWrapper{
    flex-direction: column;
    display: flex;
}
.textAreaWrapper{
  margin-left: 0px;
}
.footer{
  text-align: left;
}
.footer ul{
  list-style: none;
  flex-direction: column;
  display: block;
}
.footer li{
  display: block;
}
.submitButtonContainer{
  margin-bottom: 10em
}
.buttonContainer{
  padding-bottom: 20px
}
}
@media (min-width: 768px) and (max-width: 1024px){
  .rowWrapper{
    flex-direction: column;
    display: flex;
}
.textAreaWrapper{
  margin-left: 0px;
    }
    .submitButtonContainer{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 20px;
      margin-bottom: 10em
    }
    .contactContainer{
      margin-left: 0px;
    }
    .rowWrapper1{
      /* flex-direction: column; */
      margin-left: 20px;
    }
    .buttonContainer{
      padding-bottom: 20px
    }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  
}
 @media (min-width: 1025px) and (max-width: 1280px){
 }
 @media (min-width: 1281px) {

 }