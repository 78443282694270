html {
  box-sizing: border-box;
}
body {
  margin: 0;
  line-height: normal;
  overflow-x: hidden !important;
  width: 100% !important;
  background-color: #202492;
}

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0.5rem 0rem;
  background-color: #202492;
  color: #fff;
}

.brand-name {
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
  padding-top: 10px;
  height: 30px;
  min-width: 80px;
  border-radius: 50%;
  border: 1px solid #fff;
  border-color: transparent;
}

.navigation-menu li:hover {
  background-color: #141984;
}

.navigation-menu li.active {
  border-color: #fff;
  background-color: #141984;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: #fff;
  /* padding-left: 15px; */
  text-align: center;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #2642af;
}
.logoWrapper {
  padding-left: 60px;
}
.img {
  width: 200px;
  height: 100px;
  object-fit: contain;
}



@media screen and (max-width: 480px) {
  .hamburger {
    display: block;
  }
  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color:  #202492;
    border-top: 1px solid white;
    display: none;
    z-index: 1;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
    padding-bottom: 8px;
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    /* padding: 1.5rem 0; */
    font-size: 20px;
    padding-top: 5px;
  }

  .navigation-menu li a button {
  margin: 0px auto  !important;
  
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu.expanded ul {
    display: block;
  }
  .navigation-menu li.active {
    padding-top: 0px;
    border-color: #fff;
    background-color: white;
    border-radius: 0%;
    border: none;
    position: relative;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .logoWrapper {
    padding-left: 10px;
  }
}


@media (min-width: 481px) and (max-width: 767px) {
  
}
@media (min-width: 768px) and (max-width: 1024px){
  .logoWrapper {
    padding-left: 20px;
  }
  
}
 @media (min-width: 1025px) and (max-width: 1280px){
  
 }
 @media (min-width: 1281px) {
  
 }