.container {
  width: 100%;
  overflow-x: hidden;
}
.imgWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.responsiveImage {
  width: 100%;
  height: auto;
  margin: auto;
}
.responsiveImageTitle{
  width: 100%;
  height: auto;
  margin: auto;
}
.button1 {
  background-color: #000;
  height: 50px;
  border-radius: 6px;
  border-width: 1px;
  border-color: #fff;
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 1em;
  cursor: pointer;
}
.button2 {
  background-color: #f9e300;
  height: 50px;
  border-radius: 6px;
  border-width: 2px;
  border-color: #fff;
  display: flex;
  align-items: center;
  padding: 1em;
  cursor: pointer;
}
.rowWrapperContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
  padding-top: 1em;
  padding-bottom: 5em;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.textButtonStyle1 {
  color: #fff;
  font-size: 12px;
}
.textButtonStyle2 {
  color: #fff;
  font-size: 16px;
}
.textButtonStyle3 {
  color: #000;
  font-size: 12px;
}
.textButtonStyle4 {
  color: #000;
  font-size: 16px;
}
.contentText1 {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  margin-left: 60px;
  padding-bottom: 15px;
  padding-top: 15px;
  /* font-size:5vw */
}
.contentText2 {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  /* width: 600px; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  margin-right: 30px;
}
.contentText3 {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  /* width: 600px; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.contentText4 {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  /* width:600px; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  margin-right: 30px;
}
.columnWrapper {
  flex-direction: column;
  display: flex;
  margin-left: 180px;
}
.columnWrapper2 {
  flex-direction: column;
  display: flex;
  /* margin-left: 180px; */
}
.contentTitleText1 {
  color: #f9e300;
  font-size: 24px;
  font-weight: 600;
  margin-left: 60px;
  margin-top: 20px;
}
.contentTitleText2 {
  color: #f9e300;
  font-size: 48px;
  font-weight: 600;
}
.contentTitleText4 {
  color: #f9e300;
  font-size: 48px;
  font-weight: 600;
  margin-top: 120px;
}
.contentTitleText3 {
  color: #f9e300;
  font-size: 48px;
  font-weight: 600;
  margin-top: 160px;
  text-align: left;
}
.background1 {
  background-color: #202492;
  width: 100%;
}

.background2 {
  background-color: #1d1935;
  width: 100%;
}
.background3 {
  background-color: #5d1198;
  width: 100%;
}
.background4 {
  background-color: #125165;
  width: 100%;
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  background-color: #1d1935 !important;
  padding-bottom: 20px;
}
.footer ul {
  list-style: none;
}
.footer li a {
  text-decoration: none;
  width: 100%;
  color: #fff;
  padding-right: 50px;
}

/* media queries */

@media (max-width: 480px) {
  body {
    overflow-x: hidden !important;
  }
  .rowWrapperContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .button1 {
    margin-bottom: 1em;
 margin-right: 0;
  }
  .button2{
    margin-bottom: 1em;
    padding: 1.1em;
  }
  .buttonWrapper {
    margin-left: 0px;
    flex-direction: column;
    align-items: center;
    justify-self: center;
  }
  .imgWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }
 
  .contentText1 {
    margin-left: 15px;
    align-self: center;
    font-size: 14px;
    margin-right: 8px;
  }
  .contentText2 {
    align-self: center;
    font-size: 14px;
    padding-bottom: 20px;
    margin-right: 8px;
    margin-left: 15px;
  }
  .contentText3 {
    margin-left: 15px;
    align-self: center;
    font-size: 14px;
    padding-bottom: 20px;
    margin-right: 8px;
  }
  .contentText4 {
    margin-left: 15px;
    align-self: center;
    font-size: 14px;
    margin-right: 8px;
    padding-bottom: 20px;
  }
  .contentTitleText1 {
    color: #f9e300;
    margin-left: 15px;
  }
  
  .contentTitleText2 {
    font-size: 26px;
    margin-left: 15px;
    padding-bottom: 10px;
  }
  .contentTitleText3 {
    margin-top: 0px;
    font-size: 26px;
    margin-left: 15px;
    padding-bottom: 10px;
  }
  .contentTitleText4 {
    font-size: 26px;
    margin-left: 15px;
    padding-bottom: 10px;
  }
  .columnWrapper {
    flex-direction: column;
    display: flex;
    margin-left: 0px;
  }
  .columnWrapper2 {
    flex-direction: column;
    display: flex;
  }
  .second {
    order: 1;
  }
  .first {
    order: 2;
  }
  .responsiveImageTitle{
    width: 90%;
    height: auto;
    margin: auto;
  }
  .footer{
    text-align: left;
    padding-left: 15px;
    padding-bottom: 20px;
  }
  .footer li a{
    height: 50px;
 
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .rowWrapperContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  
}
@media (min-width: 768px) and (max-width: 1024px) {
  .columnWrapper {
    flex-direction: column;
    display: flex;
    margin-left: 10px;
  }
  .contentText2 {
    padding-bottom: 10px;
  }
  .contentText3 {
    padding-bottom: 10px;
  }
  .contentText4 {
    padding-bottom: 10px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
}
@media (min-width: 1281px) {
}
