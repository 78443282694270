.mainDiv{
  width: 100%;
  overflow-x: hidden;
}
.wrapperStyle{
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 50px;
  }
.textPadiing{
  padding-top: 20px;
  font-family: Syne;
font-style: normal;
font-size: 18px;
line-height: 30px;
text-align: left;
margin-right:80px;
color: #fff;
text-justify: auto;
font-family: 'Syne';
}
.textLink{
  text-decoration: none;
  padding-top: 20px;
  font-family: Syne;
font-style: normal;
font-size: 18px;
line-height: 30px;
text-align: left;
margin-right:80px;
color: #fff;
font-weight: 600;
}
.titleText{
  padding-top: 20px;
  font-family: Syne;
font-style: normal;
font-size: 18px;
line-height: 30px;
text-align: left;
margin-right:80px;
color: #fff;
font-weight: bold;
}
.listText{
  padding-top: 10px;
  font-family: Syne;
font-style: normal;
font-size: 16px;
line-height: 30px;
text-align: left;
margin-right:80px;
color: #fff;
font-weight: bold; 
}
.textStyle{
  font-size: 48px;
  line-height: 71px;
  font-weight: 400;
  font-family: 'Slackey';
  color: #F9E300;
  
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  background-color: #1D1935
}
.footer ul{
  list-style: none;
}
.footer li a {
  text-decoration: none;
  width: 100%;
  color: #fff;
  padding-right: 50px;

}
@media screen and (max-width:480px) {
  body {
    overflow-x: hidden!important
  }
  .footer{
    text-align: left;
    padding-left: 15px;
  }
  .footer li a{
    height: 50px;
  }
}