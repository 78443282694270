@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.google.com/specimen/Syne');
.App {
  font-family: 'Inter', sans-serif;
}

form {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  align-self: flex-start;
}

/* Input style */
.textarea{
  resize: none;
  height: 222px;
  border-radius: 4px;
  border: none;
  padding: 24px 16px 4px 16px;
  font-size: 18px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 80vh;
  margin-left: 20px;
  background-color:#001D42 ;
  color: #fff;
  
}
.textarea-container {
  position: relative;
  display: flex;
  flex-direction: column;
}


.textarea-container input {
  height: 55px;
  border-radius: 4px;
  border: none;
  padding: 24px 16px 4px 16px;
  font-size: 18px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 100vh;
  margin-left: 20px;
  background-color:#001D42 ;
  color: #fff;
}

.textarea-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  left: 16px;
  margin-left: 20px;
}

.textarea-container:focus-within label {
  transform: translate(0, 8px) scale(0.8);
  color: gray;
}

.textarea-container .filled {
  transform: translate(0, 12px) scale(0.8);
}
.textarea-container textarea:focus {
  box-shadow: 0 0 0 1px #F9E300;
}
@media (max-width: 480px) {
  body{
    overflow-x: hidden!important;
  }
  .textarea {
    width:80%
  }
  
}

@media (min-width: 481px) and (max-width: 767px) {
  body{
    overflow-x: hidden!important;
  }
  .textarea {
    width:80%
  }
  
}
@media (min-width: 768px) and (max-width: 1024px){
  .textarea {
    width:80%
  }
}

 @media (min-width: 1025px) and (max-width: 1280px){
  .textarea {
    width:80vh
  }
 }
 @media (min-width: 1281px) {
  .textarea{
    width: 70vh;
  }
 }